import React, { useEffect, useState, useRef } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import { MdOutlineEdit, MdOutlineHomeWork, MdStar, MdOutlinePhone, MdOutlineMailOutline } from "react-icons/md"
import axiosConfig from "../Service/axiosConfig"
import { useParams, useNavigate } from "react-router-dom";
import { BiArrowBack, BiCommentEdit } from "react-icons/bi";
import { RiMapPin2Line } from "react-icons/ri"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { IoNavigate } from "react-icons/io5";
import { FaLessThanEqual } from "react-icons/fa";
import { ClientTariff } from "./Tariff";
import { RxCross2 } from "react-icons/rx";
import { type } from "@testing-library/user-event/dist/type";

const ClientEdit = ({ Code }) => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [LoadingButton, setLoadingButton] = useState();
    const [key, setKey] = useState('Info');
    const [readOnly, setReadOnly] = useState(true);
    const [readOnly2, setReadOnly2] = useState(true);
    const [readOnly3, setReadOnly3] = useState(true);
    const [popupType, setpopupType] = useState("");
    const [loading, setLoading] = useState(false)
    const MastersModal = Masters.ClientEdit
    const MastersModal2 = Masters.ClientStore
    const MasterModal3 = Masters.ClientAgreement
    const [error, setError] = useState(null);
    const [Data, SetData] = useState({
        client_name: '',
        logo: '',
        email: '',
        mobile: '',
        address: '',
        latitude: '',
        longitude: '',
        client_status: 'Active',
        agreement: '',
        business_pan: '',
        partner_aadhar: '',
        company_firm: '',
        gst: '',
        trade_license: '',
        address_proof: '',
        cancelled_check: ''
    });
    const [storeData, setStoreData] = useState({
        store: '',
        delivery_agent: ''
    })
    const [ClientData, setClientData] = useState({
        client_type: '',
        contract_from_date: '',
        contract_to_date: '',
        agreement: ''
    })
    const GetData = async () => {
        try {
            setLoading(true);

            const response = await axiosConfig.get(`/clients/clients/${id}/`);
            const client_payment_res = await axiosConfig.get(`/masters/client-businessvertical-payments/?client=${id}&is_suspended=`)

            const deliveryAgentId = response.data.delivery_agent ? response.data.delivery_agent.id : null;
            const storeId = response.data.store ? response.data.store.id : null;

            const Client_data = {
                ...response.data,
                delivery_agent: deliveryAgentId,
                store: storeId,
            };

            let payment_type = client_payment_res.data.results[0]?.payment_type;

            SetData({
                ...response.data,
                business_vertical: response.data.business_vertical?.id,
                payment: payment_type.id,
            });

            setStoreData(Client_data);

            const formattedToDate = response.data.contract_to_date
                ? new Date(response.data.contract_to_date).toISOString().split('T')[0]
                : null;

            const formattedFromDate = response.data.contract_from_date
                ? new Date(response.data.contract_from_date).toISOString().split('T')[0]
                : null;

            setClientData({
                ...response.data,
                contract_to_date: formattedToDate,
                contract_from_date: formattedFromDate
            });

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const submitFormData = (formData, setFieldErrors) => {
        console.log(formData, "formdata")
        setLoadingButton(true)
        axiosConfig
            .patch(`/clients/clients/${id}/`, formData)
            .then((res) => {
                console.log(res);
                setReadOnly(true);
                setReadOnly2(true);
                setReadOnly3(true);
                setLoadingButton(false)
                setpopupType('success');
                GetData();
            })
            .catch((error) => {
                console.log(error, "error");
                if (error.response.data.error) {
                    setFieldErrors(prev => ({ ...prev, ["slug"]: "A Miscellaneous Page with this slug already exists." }));
                }
                if (error.response.data.email) {
                    setFieldErrors(prev => ({ ...prev, ["email"]: "Email already exists." }));
                }
                if (error.response.data.detail) {
                    setError(error.response.data.detail)
                }
                setReadOnly(false);
                setReadOnly2(false);
                setReadOnly3(false);
                setpopupType('error');
                setLoadingButton(false)
            });
    };
    const formSubmit = (formData, setFieldErrors) => {

        console.log(formData, "formdata")
        const jsonData = {
            client_name: formData.client_name,
            logo: formData.logo?.id || null,
            email: formData.email,
            mobile: formData.mobile,
            address: formData.address,
            latitude: formData.latitude,
            longitude: formData.longitude,
            business_vertical: formData.business_vertical,
            business_pan_image: formData.business_pan_image?.id || null,
            business_pan_no: formData.business_pan_no,
            partner_aadhar_image: formData.partner_aadhar_image?.id || null,
            partner_aadhar_no: formData.partner_aadhar_no,
            company_firm_image: formData.company_firm_image?.id || null,
            company_firm_no: formData.company_firm_no,
            gst_no: formData.gst_no,
            gst_image: formData.gst_image?.id || null,
            trade_license_image: formData.trade_license_image?.id || null,
            trade_license_no: formData.trade_license_no,
            address_proof: formData.address_proof?.id || null,
            cancelled_check: formData.cancelled_check?.id || null,
            payment: formData.payment || '',
        };

        // set field error for payments
        if (formData.payment.length === 0) {
            setFieldErrors(prev => ({ ...prev, ["payment"]: "Select at least one payment type" }));
            return;
        }

        submitFormData(jsonData, setFieldErrors);
    };

    const formSubmit2 = (formData, setFieldErrors) => {
        let data_to_send = {
            ...formData,
            business_vertical: formData.business_vertical?.id || null,
            business_pan_image: formData.business_pan_image?.id || null,
            partner_aadhar_image: formData.partner_aadhar_image?.id || null,
            company_firm_image: formData.company_firm_image?.id || null,
            gst_image: formData.gst_image?.id || null,
            trade_license_image: formData.trade_license_image?.id || null,
            logo: formData.logo?.id || null,
            payment: Data.payment || '',
        }
        submitFormData(data_to_send, setFieldErrors);
    };
    const formSubmit3 = (formData, setFieldErrors) => {
        console.log(formData);
        if (formData.client_type === "Contracted") {
            if (!formData.contract_from_date || !formData.contract_to_date) {
                setFieldErrors(prev => ({ ...prev, ["contract_from_date"]: "Required" }));
                setFieldErrors(prev => ({ ...prev, ["contract_to_date"]: "Required" }));
                return;
            }
            if (formData.contract_to_date < formData.contract_from_date) {
                setFieldErrors(prev => ({ ...prev, ["contract_to_date"]: "To date is not less than from date." }));
                return;
            }
        }
        setError(null);
        const formDataToSend = new FormData()
        formDataToSend.append("client_type", formData.client_type);
        formDataToSend.append("contract_from_date", formData.contract_from_date ? formData.contract_from_date : '');
        formDataToSend.append("contract_to_date", formData.contract_to_date ? formData.contract_to_date : "");
        formDataToSend.append("agreement", formData.agreement ? formData.agreement.id : '');
        submitFormData(formDataToSend, setFieldErrors);
    };
    const handleMapClick = (latitude, longitude) => {
        console.log(latitude, longitude)
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}&travelmode=driving`)
    }
    console.log(Data)
    useEffect(() => {
        GetData()
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="client-options">
                    <BiArrowBack onClick={() => navigate("/dashboard/masters/clients/")} className="option-icon" />
                </div>
                <div className="client-info">
                    <div className="client-logoo">
                        <img src={Data.logo && Data.logo.image !== undefined ? Data.logo.image : ''} alt="Client logo" className="logo-image" />
                    </div>
                    <div className="customer-info">
                        <div className="contact-detile">
                            <MdOutlineHomeWork className="contact-icons" />
                            <div className="data-text">{Data.client_name}</div>
                            <a href={`tel:+${Data.mobile}`}><MdOutlinePhone className="support-icons" /></a>
                        </div>
                        <div className="contact-map">
                            <RiMapPin2Line className="contact-icons" />
                            <div className="data-text">{Data.address}</div>
                            <IoNavigate className="support-icons" onClick={() => handleMapClick(Data.latitude, Data.longitude)} />
                        </div>
                        <div className="contact-rating">
                            <div className="contact-email contact-emails"><MdOutlineMailOutline className="contact-icons" />{Data.email}</div>
                            <div className="contact-phone"><MdOutlinePhone className="contact-icons" />{Data.mobile}</div>
                        </div>
                        <div className={`client-type ${Data.client_type === 'Contracted' ? 'contract-background' : 'variable-background'}`}>{Data.client_type}</div>
                    </div>
                </div>
                <div className="client-form">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3">
                        <Tab eventKey="Info" title="Info">
                            {/* <ul className="client-payments">
                                {clientPayments?.length > 0 ? (
                                    clientPayments.map((item) => (
                                        <li className="client-type contract-background" key={item.id}>
                                            {item.name}
                                            <RxCross2 size={10} className={`ms-3 ${readOnly ? 'disabled' : 'pointer'}`} onClick={() => deletePaymentType(item.id)} />
                                        </li>
                                    ))
                                ) : (
                                    <li>No payment types available</li>
                                )}
                            </ul> */}

                            <FormParser
                                modelObject={MastersModal}
                                formSubmit={formSubmit}
                                formData={Data}
                                error={Error}
                                buttonTitle='Save'
                                LoadingButton={LoadingButton}
                                setLoadingButton={setLoadingButton}
                                readOnly={readOnly}
                                setReadOnly={setReadOnly}
                            />
                            {readOnly &&
                                <button className={`btn btn-primary d-flex align-items-center`} onClick={() => setReadOnly(false)}>
                                    <div>Edit</div>
                                    <MdOutlineEdit className="ms-5" />
                                </button>
                            }
                        </Tab>
                        <Tab eventKey="Store & Delivery Agent" title="Store & Delivery Agent">
                            <FormParser
                                modelObject={MastersModal2}
                                formSubmit={formSubmit2}
                                formData={storeData}
                                error={Error}
                                buttonTitle='Save'
                                readOnly={readOnly2}
                                setReadOnly={setReadOnly2}
                            />
                            {readOnly2 &&
                                <button className={`btn btn-primary d-flex align-items-center`} onClick={() => setReadOnly2(false)}>
                                    <div>Edit</div>
                                    <MdOutlineEdit className="ms-5" />
                                </button>
                            }
                        </Tab>
                        <Tab eventKey="Agreements" title="Agreements">
                            <FormParser
                                modelObject={MasterModal3}
                                formSubmit={formSubmit3}
                                formData={ClientData}
                                error={error}
                                buttonTitle='Save'
                                readOnly={readOnly3}
                                setReadOnly={setReadOnly3}
                            />
                            {readOnly3 &&
                                <button className={`btn btn-primary d-flex align-items-center`} style={{ marginLeft: '30px' }} onClick={() => setReadOnly3(false)}>
                                    <div>Edit</div>
                                    <MdOutlineEdit className="ms-5" />
                                </button>
                            }
                        </Tab>
                        <Tab eventKey="Tariff" title="Tariff">
                            <ClientTariff />
                        </Tab>
                    </Tabs>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" setpopupType={setpopupType} Message="Client Updated Sucessfully" error={error} />
                }
            </DashboardLayout >
        </>
    )

}
export { ClientEdit }