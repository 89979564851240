import React, { useEffect, useState } from "react";
import { DashboardLayout, PopupModal, FormParser } from "../Components";
import { BillsComponent } from "../Components/navbar";
import { BiSearch } from "react-icons/bi";
import Select from "react-select";
import { useNavigate } from "react-router";
import axiosConfig from "../Service/axiosConfig";
import DataTable from "react-data-table-component";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { FaHandHoldingHeart } from "react-icons/fa";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import useDebouncedValue from "../Utils/Debounce";
import { AiOutlineFileExcel } from "react-icons/ai";
import { downloadExcel } from "react-export-table-to-excel";
import Modal from "react-bootstrap/Modal";
import Masters from "../Components/masters.json";
import NumberFormat from "../Utils/NumberFormat";

const GRN = () => {
  const navigate = useNavigate();
  const MastersModal = Masters.CreateGRN;
  const [grns, setGrns] = useState([]);
  const [totals, setTotals] = useState({});
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [Id, SetId] = useState();
  const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
  const [RowId, SetRowId] = useState();
  const [deletePopupType, setDeletepopupType] = useState("");
  const [popupType, setpopupType] = useState("");
  const [Error, SetError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  let today_date = new Date().toISOString().split("T")[0];
  const [filters, setFilters] = useState({
    from_date: today_date,
    to_date: today_date,
    search: "",
  });
  const [loadCsv, setLoadCsv] = useState(false);
  const debouncedSearchTerm = useDebouncedValue(filters.search, 500);
  const [show, setShow] = useState(false);
  const [LoadingButton, setLoadingButton] = useState();

  const tableCustomStyles = {
    rows: {
      style: {},
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        color: "#5C5C5C",
        justifyContent: "center",
        color: "rgb(33,37,41)",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "28px",
      },
    },
  };

  const columns = [
    {
      name: "GRN No",
      selector: "grn_no",
      sortable: true,
      width: "100px",
      reorder: true,
    },
    {
      name: "Vendor",
      cell: (row) => <div>{row.vendor_name}</div>,
      sortable: true,
      width: "150px",
      wrap: true,
      reorder: true,
    },
    {
      name: "Discount Amount",
      selector: "discount_amount",
      cell: (row) => `₹ ${NumberFormat(row.discount_amount)}`,
      sortable: true,
      minWidth: "150px",
      right: true,
      reorder: true,
    },
    {
      name: "Net Amount",
      selector: "net_amount",
      cell: (row) => `₹ ${NumberFormat(row.net_amount)}`,
      minWidth: "150px",
      right: true,
      reorder: true,
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: "total_amount",
      cell: (row) => `₹ ${NumberFormat(row.total_amount)}`,
      minWidth: "150px",
      right: true,
      reorder: true,
      sortable: true,
    },
    {
      name: "Total Quantity",
      selector: "total_qty",
      right: true,
      center: true,
      reorder: true,
      sortable: true,
    },
    {
      name: "Created date",
      selector: "created_date",
      sortable: true,
      width: "200px",
      reorder: true,
    },
    {
      name: "Modified date",
      selector: "modified_date",
      sortable: true,
      width: "200px",
      reorder: true,
    },
    {
      name: "Date of supply",
      selector: "date_of_supply",
      sortable: true,
      reorder: true,
    },
    {
      name: "ACTIONS",
      selector: (row) => {
        const HandleEdit = () => {
          navigate(`/dashboard/masters/grn/${row.grn_id}/modify`, {
            state: { grn_data: row, vendor_data: row.vendor },
          });
        };
        const HandleExcel = (expenditure) => {
          const url = `${axiosConfig.defaults.baseURL}accounts/download-grn/${expenditure.grn_id}/`;
          window.open(url, "_blank");
        };
        const DeleteAlert = () => {
          SetRowId(row.grn_id);
          SetAlertDeletePopupType("success");
        };
        const CancelAlerrt = () => {
          SetAlertDeletePopupType("error");
        };
        const HandleDelete = async (row_id) => {
          await axiosConfig
            .delete(`accounts/grn/${row_id}/`)
            .then(() => {
              SetAlertDeletePopupType("error");
              setDeletepopupType("success");
              GetData();
            })
            .catch((error) => {
              setDeletepopupType("error");
              SetAlertDeletePopupType("error");
              console.log(error);
            });
        };
        const handleRevive = async () => {
          try {
            const response = await axiosConfig.post(
              `accounts/grn/${row.grn_id}/revive/`
            );
            setpopupType("success");
            setMessage("GRN revived successfully");
            GetData();
          } catch (error) {
            console.log("Error While Reviving", error);
            setpopupType("error");
            SetError(error);
          }
        };
        return (
          <>
            {Id === row.grn_id ? (
              row.is_suspended ? (
                <FaHandHoldingHeart
                  className="dashboard-tabe-data-actions"
                  onClick={handleRevive}
                />
              ) : (
                <>
                  <div className="row">
                    <div className="col-3">
                      <MdOutlineEdit
                        className="dashboard-tabe-data-actions"
                        onClick={HandleEdit}
                      />
                    </div>
                    <div className="col-3">
                      <MdDeleteOutline
                        className="dashboard-tabe-data-actions"
                        onClick={DeleteAlert}
                      />
                    </div>
                    <div className="col-3">
                      <AiOutlineFileExcel
                        className="dashboard-tabe-data-actions"
                        onClick={() => HandleExcel(row)}
                      />
                    </div>
                  </div>
                </>
              )
            ) : (
              <div
                className={`client-type ${
                  row.is_suspended
                    ? "variable-background"
                    : "contract-background"
                }`}
              >
                {row.is_suspended ? "Suspended" : "Active"}
              </div>
            )}
            {popupType != "" && (
              <PopupModal
                setpopupType={setpopupType}
                popupType={popupType}
                Navigation=""
                Message={message}
                error={Error}
              />
            )}
            {AlertDeletePopupType === "success" && (
              <AlertDeletePopupModal
                AlertDeletePopupType={AlertDeletePopupType}
                HandleDelete={HandleDelete}
                CancelAlerrt={CancelAlerrt}
                row={RowId}
              />
            )}
          </>
        );
      },
    },
  ];

  const header = [
    "GRN No",
    "Vendor Name",
    "Discount Amount",
    "Net Amount",
    "Total Amount",
    "Total Quantity",
    "Created Date",
    "Modified Date",
    "Date of Supply",
  ];
  const handleClose = () => {
    setShow(false);
    setpopupType("");
    SetError("");
  };
  const handleShow = () => {
    setShow(true);
    SetError("");
  };
  async function handleDownloadExcel() {
    setLoadCsv(true);
    try {
      const params = {
        vendor: selectedVendor?.value || "",
        date_of_supply_after: filters.from_date,
        date_of_supply_before: filters.to_date,
        search: filters.search,
        page_size: 1000,
      };
      const queryString = new URLSearchParams(params).toString();
      const response = await axiosConfig.get(`/accounts/grn/?${queryString}`);
      const data = response.data.results;
      if (data && data.length > 0) {
        const body = data.map((res) => [
          res.grn_no,
          res.vendor?.vendor_name,
          res.discount_amount,
          res.net_amount,
          res.total_amount,
          res.total_qty,
          res.cdate,
          res.mdate,
          res.date_of_supply,
        ]);
        downloadExcel({
          fileName: `GRN data from ${filters.from_date} to ${filters.to_date}`,
          sheet: `GRN data from ${filters.from_date} to ${filters.to_date}`,
          tablePayload: {
            header,
            body: body,
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadCsv(false);
    }
  }

  const formSubmit = async (Data) => {
    setLoadingButton(true);
    try {
      console.log(Data);
      axiosConfig.defaults.headers["Content-Type"] = "multipart/form-data";
      await axiosConfig.post(
        `/accounts/upload_grn_csv/?date_of_supply=${Data.date_of_supply}&vendor_id=${Data.vendor}`,
        {
          grn_csv: Data.grn_csv,
        }
      );
      setpopupType("success");
      setMessage("GRN created Successfully");
      GetData();
      setShow(false);
    } catch (error) {
      console.log(error);
      setpopupType("error");
      SetError("Error While uploading GRN Excel");
    } finally {
      setLoadingButton(false);
    }
  };
  const CustomHeader = () => {
    return (
      <div className="rowEndCenter">
        <div className="rowCenter gap-10">
          <button className="btn btn-primary" onClick={handleShow}>
            Upload Excel
          </button>
          <AiOutlineFileExcel
            className={`report-icons ${loadCsv ? "disable" : ""}`}
            onClick={handleDownloadExcel}
          />
        </div>
      </div>
    );
  };
  const HandleNavigate = () => {
    navigate("/dashboard/masters/grn/add");
  };

  const HandleMouse = (row) => {
    SetId(row.grn_id);
  };
  const HandleRowmouseLeave = () => {
    SetId();
  };

  const HandleChange = (e, filter) => {
    setFilters({ ...filters, [filter]: e.target.value });
  };

  const GetData = async () => {
    try {
      // const grn_response = await axiosConfig.get(
      //   `/accounts/grn/?vendor=${
      //     selectedVendor ? selectedVendor.value : ""
      //   }&is_suspended=&date_of_supply_after=${
      //     filters.from_date
      //   }&date_of_supply_before=${filters.to_date}&search=${
      //     filters.search
      //   }&page=${currentPage}&page_size=${RowPerPage}`
      // );
      const grn_response = await axiosConfig.get(
        `/accounts/grn_reports/?from_date=${filters.from_date}&to_date=${
          filters.to_date
        }&vendor_id=${selectedVendor ? selectedVendor.value : ""}`
      );
      if (vendors.length === 0) {
        const vendor_response = await axiosConfig.get(
          "/accounts/vendors/?is_suspended=false"
        );
        let vendor_options = vendor_response.data.results.map((vendor) => ({
          label: vendor.vendor_name,
          value: vendor.id,
        }));
        setVendors(vendor_options);
      }
      setGrns(grn_response.data.data);
      setTotals(grn_response.data.totals);
      setTotalRows(grn_response.data.data.length);
    } catch (error) {
      console.log("error getting grns", error);
    }
  };

  useEffect(() => {
    GetData();
  }, [
    selectedVendor,
    filters.from_date,
    filters.to_date,
    debouncedSearchTerm,
    currentPage,
    RowPerPage,
  ]);

  return (
    <>
      <DashboardLayout>
        <div className="child-sidenav">
          <BillsComponent />
          <div className="childnav-content">
            <div className="row mb-3">
              <div className="col-3">
                <input
                  type="date"
                  className="form-control"
                  value={filters.from_date}
                  onChange={(e) => HandleChange(e, "from_date")}
                />
              </div>
              <div className="col-3">
                <input
                  type="date"
                  className="form-control"
                  value={filters.to_date}
                  onChange={(e) => HandleChange(e, "to_date")}
                />
              </div>
              <div className="col-4">
                <div className="input-cat-with-icon pe-0">
                  <span className="icon-product">
                    <BiSearch />
                  </span>
                  <input
                    type="text"
                    className="input-control"
                    placeholder="Search"
                    name="search"
                    onChange={(e) => HandleChange(e, "search")}
                  />
                </div>
              </div>
              <div className="col-2">
                <div class="cat-button">
                  <div class="btn" onClick={() => HandleNavigate()}>
                    + Add New
                  </div>
                </div>
              </div>
            </div>
            <Select
              placeholder="Select Vendor"
              options={vendors}
              value={selectedVendor}
              onChange={(selectedOption) => setSelectedVendor(selectedOption)}
              className="mb-3"
            />

            <div>
              <div className=" row rowBetweenCenter f_14_500">
                <div className="col-3">Total Discount Amount</div>
                <div className="col-3 f_14_400">{`₹ ${NumberFormat(
                  totals.total_discount
                )}`}</div>
                <div className="col-3">Total Net Amount</div>
                <div className="col-3 f_14_400">{`₹ ${NumberFormat(
                  totals.total_net_amount
                )}`}</div>
              </div>
            </div>
            <div>
              <div className=" row rowBetweenCenter f_14_500">
                <div className="col-3">Amount Total</div>
                <div className="col-3 f_14_400">{`₹ ${NumberFormat(
                  totals.total_amount
                )}`}</div>
                <div className="col-3">Quantity Total</div>
                <div className="col-3 f_14_400">{`₹ ${NumberFormat(
                  totals.total_quantity
                )}`}</div>
              </div>
            </div>

            <div className="table-responsive marginTop" style={{ zIndex: "0" }}>
              <DataTable
                title={<CustomHeader />}
                columns={columns}
                data={grns}
                pagination
                paginationTotalRows={totalRows}
                paginationPerPage={RowPerPage}
                paginationDefaultPage={currentPage}
                onChangePage={(page) => setCurrentPage(page)}
                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                onRowMouseEnter={HandleMouse}
                onRowMouseLeave={HandleRowmouseLeave}
                onChangeRowsPerPage={(RowsPerPageChange) =>
                  setRowPerPage(RowsPerPageChange)
                }
                // onSort={handleSort}
                paginationServer
                fixedHeader
                fixedHeaderScrollHeight="70vh"
                subHeader
                customStyles={tableCustomStyles}
                progressPending={loading}
                progressComponent={
                  <div className="loader-container">
                    <img
                      src={require("../Components/FormParser/images/loader2.gif")}
                      alt="Loading..."
                    />
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Create GRN through Excel
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <FormParser
                modelObject={MastersModal}
                formSubmit={formSubmit}
                LoadingButton={LoadingButton}
                button="btn-block"
              />
            </div>
          </Modal.Body>
        </Modal>
      </DashboardLayout>
    </>
  );
};

export { GRN };
